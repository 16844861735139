<template>
  <div class="login-wrap">
    <div class="login-card">
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" auto-complete="off" class="login-form" size="large">
        <div class="form-title">AUV液体化学品智慧物流</div>
        <el-form-item prop="phone" label="账户/手机号">
          <el-input v-model.trim="loginForm.phone" v-focus autocomplete="off" placeholder="请输入账户/手机号" tabindex="1" maxlength="11" type="text" />
        </el-form-item>
        <el-form-item prop="password" label="密码">
          <el-input ref="password" v-model.trim="loginForm.password" type="password" autocomplete="off" placeholder="请输入你的密码" tabindex="2" show-password @keyup.enter.native="handleLogin" />
        </el-form-item>
        <div class="flex-b-c">
          <el-checkbox v-model="loginForm.rememberMe" size="medium" label="记住密码" />
          <div class="right forget" @click="forgetPwd">忘记密码？</div>
        </div>
        <el-button :loading="loading" class="login-btn" type="primary" @click="handleLogin">登录 </el-button>
      </el-form>
    </div>
    <!-- <div class="copyright">
      <span> Copyright © 2022 江苏五城星耀网络科技有限公司 版权所有 </span>
      <a class="icp" href="https://beian.miit.gov.cn/" target="_blank"> 苏ICP备2021030718号-1 </a>
    </div> -->
  </div>
</template>

<script>
import md5 from 'js-md5';
import { validatePwd, validateTel } from '@/utils/validateRule';
import Cookies from 'js-cookie';
import { encrypt, decrypt } from '@/utils/jsencrypt';
export default {
  name: 'Login',
  directives: {
    focus: {
      inserted(el) {
        el.querySelector('input').focus();
      },
    },
  },
  data() {
    return {
      loginForm: {
        phone: '',
        password: '',
        rememberMe: false,
      },
      loginRules: {
        phone: [
          {
            trigger: 'blur',
            validator: validateTel,
          },
        ],
        password: [
          {
            trigger: 'blur',
            validator: validatePwd,
          },
        ],
      },
      loading: false,
      redirect: undefined,
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    this.getCookie();
  },
  methods: {
    getCookie() {
      const phone = Cookies.get('phone-boss');
      const password = Cookies.get('password-boss');
      const rememberMe = Cookies.get('rememberMe-boss');
      this.loginForm = {
        phone: phone === undefined ? this.loginForm.phone : phone,
        password: password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
      };
    },
    forgetPwd() {
      this.$router.push('/forget');
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          const params = {
            loginType: 1, // 登录类型：1密码登录 2验证码
            username: this.loginForm.phone, // 登录账号/手机号
            password: md5(this.loginForm.password), // 密码/验证码
            // password: this.loginForm.password, // 密码/验证码

          };
          this.$store.dispatch('common/login', params).then((res) => {
            this.loading = false;
            if (!res) return;
            if (this.loginForm.rememberMe) {
              Cookies.set('phone-boss', this.loginForm.phone, { expires: 30 });
              Cookies.set('password-boss', encrypt(this.loginForm.password), { expires: 30 });
              Cookies.set('rememberMe-boss', this.loginForm.rememberMe, { expires: 30 });
            } else {
              Cookies.remove('phone-boss');
              Cookies.remove('passworde-boss');
              Cookies.remove('rememberMee-boss');
            }
            Cookies.set('phone-boss', this.loginForm.phone, { expires: 30 });
            this.$message.success('登录成功');
            setTimeout(() => {
              this.$router.push({ path: this.redirect || '/' }).catch(() => {});
            }, 200);
          }).catch(() => {
            this.loading = false;
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-wrap {
  height: 100vh;
  background: url("~@/assets/images/login/login-bg.jpg") center center fixed no-repeat;
  background-size: cover;
  .login-card {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 460px;
    overflow: hidden;
    display: flex;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 8px;
    .login-form {
      position: relative;
      overflow: hidden;
      width: 460px;
      padding: 56px;
      .form-title {
        font-size: 28px;
        padding-bottom: 32px;
      }
      .forget {
        cursor: pointer;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #58595c;
        line-height: 22px;
      }
      .login-btn {
        margin-top: 25px;
        width: 100%;
        height: 52px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .copyright {
    position: absolute;
    bottom: 10px;
    color: rgb(207, 202, 202);
    margin-left: calc(50% - 275px);
    .icp {
      cursor: pointer;
      margin-left: 5px;
      color: rgb(207, 202, 202);
      &:hover {
        color: #fff;
      }
    }
  }
}
::v-deep {
  .el-checkbox__inner {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border: 1px solid #dbdcdf;
    // 1px solid #dcdfe6
  }
  .el-checkbox__inner:after {
    margin: 1px 0 0 1px;
  }
  .el-checkbox__label {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #58595c;
    line-height: 22px;
  }
  .el-form-item__label {
    margin-bottom: 8px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #26272a;
    line-height: 22px;
  }
  .el-input input {
    height: 52px;
    line-height: 50px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
  .el-input__prefix {
    display: inline-flex;
    align-items: center;
    left: 10px;
  }
  .el-input__suffix {
    display: inline-flex;
    align-items: center;
  }
}
</style>
